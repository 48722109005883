@font-face {
  font-family: 'Sharp Sans Display No2';
  src: url('fonts/SharpSansDispNo2-Extrabold.woff2') format('woff2'),
    url('fonts/SharpSansDispNo2-Extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  src: url('fonts/SharpSansDispNo2-Bold.woff2') format('woff2'),
    url('fonts/SharpSansDispNo2-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  src: url('fonts/SharpSansDispNo2-Semibold.woff2') format('woff2'),
    url('fonts/SharpSansDispNo2-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sharp Sans Display No2';
  src: url('fonts/SharpSansDispNo2-Medium.woff2') format('woff2'),
    url('fonts/SharpSansDispNo2-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
// @font-face {
//   font-family: 'Sharp Sans Display No2';
//   src: url('fonts/SharpSansDispNo2-Extrabold.woff2') format('woff2'),
//     url('fonts/SharpSansDispNo2-Extrabold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Sharp Sans Display No2';
//   src: url('fonts/SharpSansDispNo2-Bold.woff2') format('woff2'),
//     url('fonts/SharpSansDispNo2-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Sharp Sans Display No2';
//   src: url('fonts/SharpSansDispNo2-Semibold.woff2') format('woff2'),
//     url('fonts/SharpSansDispNo2-Semibold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Sharp Sans Display No2';
//   src: url('fonts/SharpSansDispNo2-Medium.woff2') format('woff2'),
//     url('fonts/SharpSansDispNo2-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }
