@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  font-family: $font;
  // font-size: 100%;
  font-size: 1.125em;
  line-height: 1.4;
  font-weight: 500;
  color: $text;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100svh;
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
    justify-content: flex-start;
  }
  &.noscroll {
    overflow: hidden;
  }
}
.container {
  // width: 100%;
  // // max-width: 60em;
  // max-width: $xxmedium;
  // margin: 0 auto;
  @include container;

  // &--wide { max-width: 94em; }
  &--narrow {
    max-width: 25em;
  }

  // @media screen and (max-width: $medium) {
  //   padding: 0 1.5em;
  // }
  // @media screen and (max-width: $small) {
  //   padding: 0 1em;
  // }
}

a {
  & > .link--ext,
  &.link--ext {
    // margin-left: 2px;
    display: inline-block;
    transition: all linear 0.2s;
    fill: $gray;
    stroke: $gray;
  }
  &:hover > .link--ext,
  &.link--ext:hover {
    fill: $blue;
    stroke: $blue;
    transform: rotate(45deg);
  }
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #dce0e6;
  opacity: 1;
  &-active {
    background-color: $gray;
  }
}
.overlay {
  @include hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(21, 38, 71, 0.15);
  transition: opacity linear 0.1s;
  &.active {
    @include visible;
    z-index: 899;
  }
}

[data-tooltip] {
  transition: all linear 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before {
    color: $color1;
    box-shadow: -7px 10px 38px rgba(156, 165, 184, 0.3);
    content: attr(data-tooltip);
    transition: all ease-in-out 0.2s;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
    padding: 1em;
    border-radius: 1em;
    white-space: normal;
    width: 120%;
    min-width: 10em;
    max-width: 25em;
    // bottom: 0;
    bottom: 40px;
    position: absolute;
    @include hidden;
  }
  &:after {
    content: '';
    // width: 0;
    // height: 0;
    // border-style: solid;
    // border-width: 8px 8px 0 8px;
    // border-color: $dark transparent transparent transparent;
    width: 20px;
    height: 10px;
    // bottom: -9px;
    bottom: 30px;
    left: 50%;
    background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.31999 7.16942L3.27995 1.98009C2.59995 0.800086 1.36 0.0560652 0 6.52128e-05H19.48C18.1066 0.0573985 16.8533 0.813383 16.1733 2.00938L13.2399 7.14008C11.7066 9.81341 7.86665 9.82942 6.31999 7.16942Z" fill="white"/></svg>');
    transform: translateX(-50%);
    position: absolute;
    @include hidden;
    transition: all ease-in-out 0.2s;
  }
  &:hover:before,
  &:hover:after {
    // &.hover:before,
    // &.hover:after {
    @include visible;
    // bottom: calc(100% + 8px);
    z-index: 101;
    &:hover {
      @include hidden;
    }
  }
  // &:hover:before {
  &.hover:before {
    bottom: calc(100% + 7px);
  }
  // &:hover:after {
  &.hover:after {
    bottom: 100%;
  }
  &.tooltip--wide:before {
    min-width: 250px;
  }
  &.tooltip--left:before {
    left: auto;
    // right: -1em;
    right: -0.7em;
    transform: none;
    text-align: center;
  }
  &.tooltip--right:before {
    left: -1em;
    // right: -1em;
    transform: none;
  }
}

.popup {
  &__container {
    width: 425px;
    max-width: 90vw;
    background-color: #fff;
    padding: 35px;
    border-radius: 30px;
    margin: 0 auto;
  }
  bottom: -100%;
  @include hidden;
  position: fixed;
  width: 100%;
  left: 0;
  transition: all ease-in-out 0.2s;
  &.active {
    bottom: 64px;
    @include visible;
    z-index: 999;
    @media screen and (max-width: $medium) {
      bottom: 1em;
      // transform: none;
    }
  }
  &__title {
    font-size: 1.44em;
    text-align: center;
  }
  &__form {
  }
  &__text {
    text-align: center;
    a {
      color: $color1;
    }
    &--narrow {
      max-width: 300px;
      margin: 0 auto 35px auto;
    }
  }
  &__btns {
    // text-align: center;
    display: flex;
    justify-content: center;
    // gap: 10px;
    margin-left: -5px;
    margin-right: -5px;
    .btn {
      flex: 1 1 100%;
      min-width: auto;
      max-width: 175px;
      margin: 0 5px;
    }
  }
}

.spinner {
  // position: fixed;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -45px;
  margin-left: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background-color: rgba(130, 229, 168, 0.2);
  // opacity: 0.22;
  border-radius: 50px;
  @include hidden;
  transition: opacity ease 1s;
  animation: greenBgFade 2s infinite alternate;
  &.active {
    @include visible;
    // transition: all ease 0.3s;
  }
  &__wrapper {
    // @include hidden;
    // transition: all ease 1s;
    // &.active {
    //   @include visible;
    // }
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // position: fixed;
    // left: 0;
    // top: 0;
    // z-index: 999;
    // width: 100%;
    // height: 100%;
    // background: transparent;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #82e5a8;
    width: 56px;
    height: 56px;
    border-radius: 50px;
    svg {
      width: 35px;
    }
  }
}
.icon-link {
  flex-shrink: 0;
}

.header {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  animation-name: fadein;
  animation-duration: 0.5s;
  // animation-delay: 0.5s;
  @media screen and (min-width: $medium) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
  &__join {
    display: none;
    @media screen and (min-width: $medium) {
      display: block;
      text-align: right;
      // line-height: 110%;
    }
  }
  @media screen and (min-width: $medium) {
    justify-content: space-between;
  }
}
.join__link {
  svg {
    // margin-left: 0.5em;
    stroke: $blue;
    fill: $blue;
  }
}

.main {
  flex: 1 1 100%;
  // margin-top: auto;
  // margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: fadein;
  animation-duration: 0.7s;
  // animation-delay: 0.5s;
  @media screen and (min-width: $small) {
    flex-direction: row;
  }
  @media screen and (min-width: $medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (min-width: $large) {
    flex: 0 1 100%;
    margin-top: 10vh;
  }
  .picture {
    display: none;
    @media screen and (min-width: $medium) {
      display: block;
    }
  }
}
.splash {
  // animation-name: fadein;
  // animation-duration: 0.5s;
  // animation-delay: 0.5s;
  @media screen and (min-width: $medium) {
    display: none;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  background-color: $blue;
  transition: opacity linear 0.4s;
  &.active {
    z-index: 999;
    pointer-events: all;
    opacity: 1;
    animation-name: fadein;
    animation-duration: 0.5s;
  }
  &__logo {
    // padding-bottom: 1em;
    flex-shrink: 0;
    margin-bottom: auto;
    text-align: center;
  }
  &__image {
    flex: 1 1 100%;
    padding: 1em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation-name: fadein;
    animation-duration: 0.8s;
    // animation-delay: 0.5s;
    img {
      margin-right: -1em;
    }
  }
  .splash__footer {
    flex-shrink: 0;
    margin-top: auto;
  }
  .splash__btns {
    background-color: rgba(255, 255, 255, 0.06);
    border-radius: 20px;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 20em;
    .btn {
      border-radius: 20px;
      flex: 0 0 50%;
      line-height: 1.25rem;
      padding: 1.25rem;
      font-size: 1em;
      text-decoration: none;
    }
    .btn-login {
      background-color: #fff;
      color: $blue;
    }
    .btn-join {
      background-color: transparent;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      svg {
        flex-shrink: 0;
        stroke: #fff;
        fill: #fff;
      }
    }
  }
}
.form-login {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  flex: 1 1 100%;
  max-width: 385px;
  // animation-name: fadein;
  // animation-duration: 0.5s;
  // animation-delay: 0.5s;
  @media screen and (min-width: $medium) {
    justify-content: center;
  }
}
.picture {
  position: relative;
  width: 486px;
  margin-left: 1.5em;
  margin-right: -1.5em;
  // animation-name: fadein;
  // animation-duration: 0.5s;
  // animation-delay: 0.7s;
  .shape {
  }
  .sticker {
    display: inline-flex;
    position: absolute;
    padding: 0.8em 0.9em;
    font-size: 0.85em;
    line-height: 130%;
    align-items: center;
    background-color: #fff;
    border-radius: 1.3em;
    box-shadow: 4px 12px 40px -10px rgba(0, 0, 0, 0.1);
    z-index: 800;
    &__icon {
      flex-shrink: 0;
      border-radius: 0.85em;
      width: 2.5em;
      height: 2.5em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8em;
    }
    & > span {
      flex: 1 1 100%;
    }
    &-1 {
      right: 0;
      top: 18%;
      // animation-name: fadein;
      // animation-duration: 0.5s;
      // animation-delay: 0.8s;
      @media screen and (min-width: $large) {
        right: -7%;
      }
      .sticker__icon {
        background-color: $green;
      }
    }
    &-2 {
      left: -7%;
      bottom: 14%;
      // animation-name: fadein;
      // animation-duration: 0.5s;
      // animation-delay: 0.9s;
      .sticker__icon {
        background-color: $blue;
      }
    }
  }
  // .car {
  //   position: absolute;
  //   left: 50%;
  //   bottom: 0;
  //   transform: translateX(-50%);
  //   z-index: 700;
  // }
  // .circle-1 {
  //   z-index: 100;
  //   position: absolute;
  // }
  // .circle-2 {
  //   position: absolute;
  //   z-index: 200;
  // }
}

.login-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.875rem;
  line-height: 110%;
  @media screen and (min-width: $small) {
    font-size: 2rem;
  }
  @media screen and (min-width: $medium) {
    font-size: 2.5rem;
  }
  @media screen and (min-width: $xxmedium) {
    font-size: 3rem;
  }
  @media screen and (min-width: $large) {
    font-size: 3.125rem;
  }
  // & + p {
  //   margin-top: 0;
  //   margin-bottom: 7vh;
  // }
}
.login-subtitle {
  margin: 0;
  .accented {
    border-bottom: 4px solid $green;
    @media screen and (min-width: $xxmedium) {
      white-space: nowrap;
      border-bottom: none;
      display: inline-block;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        background-image: url('data:image/svg+xml,<svg width="319" height="8" viewBox="0 0 319 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 5.10539C61.3333 1.93873 328.3 3.10541 315.5 5.10541" stroke="%2382E5A8" stroke-width="5" stroke-linecap="round"/></svg>');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom center;
      }
    }
  }
}

.alert {
  @include container;
  position: relative;
  margin-top: 1em;
  z-index: 800;
  // background-color: #fff;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-right: 1.25em;
  border-radius: 0.5em;
  // box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  // transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #4884fa;
  &:before {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    flex-shrink: 0;
  }
  &.alert-info,
  &.alert-success {
    background-color: rgba(115, 210, 152, 1);
    padding-left: 1.25em;
    &:before {
      content: '';
      background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
  &.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    &:before {
      // content: '';
      background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
  &.alert-danger,
  &.alert-error {
    background-color: #d82626;
    &:before {
      // content: '';
      background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
    }
  }
  .close {
    flex-shrink: 0;
    // position: absolute;
    margin-left: auto;
    right: 1rem;
    top: 1rem;
    width: 1rem;
    height: 1rem;
    display: block;
    background-image: url('data:image/svg+xml,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0001 1.59189L1.70263 13.3295" stroke="white" stroke-width="3" stroke-linecap="round"/><path d="M14.0001 13.4081L1.70264 1.67051" stroke="white" stroke-width="3" stroke-linecap="round"/></svg>');
    // background-image: url('data:image/svg+xml,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0001 1.59189L1.70263 13.3295" stroke="%23C7CACF" stroke-width="3" stroke-linecap="round"/><path d="M14.0001 13.4081L1.70264 1.67051" stroke="%23C7CACF" stroke-width="3" stroke-linecap="round"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: transparent;
  }
}
// .alert {
//   position: fixed;
//   width: 90vw;
//   max-width: 600px;
//   left: 50%;
//   top: 2em;
//   z-index: 999;
//   background-color: #fff;
//   padding: 2em;
//   border-radius: 30px;
//   box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
//   transform: translateX(-50%);
//   .close {
//     position: absolute;
//     right: 1rem;
//     top: 1rem;
//     width: 1rem;
//     height: 1rem;
//     display: block;
//     background-image: url('data:image/svg+xml,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0001 1.59189L1.70263 13.3295" stroke="%23C7CACF" stroke-width="3" stroke-linecap="round"/><path d="M14.0001 13.4081L1.70264 1.67051" stroke="%23C7CACF" stroke-width="3" stroke-linecap="round"/></svg>');
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     background-color: transparent;
//   }
// }
