label {
  display: block;
}
.form__container {
  width: 100%;
  max-width: 23em;
  margin-left: auto;
  margin-right: auto;
  &.map {
    max-width: 35em;
  }
}
.form__warning {
  position: absolute;
  margin: 1em 0;
  // padding: 1em 0;
  padding-left: 1em;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  transition: all linear 0.1s;
  &.active {
    position: relative;
    height: auto;
    overflow: visible;
    opacity: 1;
  }
  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 100%;
    background-color: $red;
    left: 0;
    top: 0;
    border-radius: 3px;
  }
}
.form__footer {
}
.form__item {
  // margin: 1em 0;
  margin-bottom: 1em;
  position: relative;
  &--text {
  }
  &--checkbox {
  }
  &--toggle {
    position: relative;
    input[type='checkbox'] {
      // display: none;
      @include hidden;
      position: absolute;
      pointer-events: none;
      & + label {
        position: relative;
        cursor: pointer;
        user-select: none;
        font-weight: 400;
        display: flex;
        flex: 1 1 100%;
        align-items: center;
        // justify-content: space-between;
        // &:before {
        //   content: '';
        //   display: inline-block;
        //   width: 1.5em;
        //   height: 1.5em;
        //   flex: 0 0 1.5em;
        //   background: #e9edef;
        //   border: 1px solid #d3dbe0;
        //   border-radius: 3px;
        //   margin-right: 0.5em;
        // }
        // &:before {
        //   display: none;
        //   width: 1em;
        //   height: 1em;
        // }
        &:before {
          content: '';
          display: inline-block;
          width: 2.75rem;
          height: 1.75rem;
          border-radius: 3.25em;
          background: #aeb8c9;
          border: 0.25rem solid #aeb8c9;
          margin-right: 0.5em;
          background-image: url('data:image/svg+xml,<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9.21427" cy="9.21427" r="9.21427" transform="matrix(-1 0 0 1 18.4285 0.785156)" fill="white"/></svg>');
          // background-image: url(img/circle-gray.svg);
          background-size: 1.25rem;
          background-position: left center;
          // background-position: 0.25rem 0.25rem;
          background-repeat: no-repeat;
          transition: all linear 0.2s;
        }
        &:hover {
          color: $gray;
        }
      }
      &:checked + label {
        &:before {
          background-position: right center;
          background-color: $blue;
          border-color: $blue;
        }
      }
    }
  }
  &--radio {
  }

  &--password {
    .btn-showpass,
    .btn-hidepass {
      // position: absolute;
      // width: 1.34em;
      // height: 1.34em;
      // right: 0.5em;
      // top: 50%;
      // margin-top: -0.67em;
    }
    .btn-showpass {
      @include visible;
    }
    .btn-hidepass {
      @include hidden;
    }
    &.show {
      .btn-showpass {
        @include hidden;
      }
      .btn-hidepass {
        @include visible;
      }
    }
  }
  .form__icon,
  .form__tooltip,
  .icon-error {
    position: absolute;
    width: 1.34em;
    height: 1.34em;
    right: 1.2em;
    top: 50%;
    margin-top: -0.67em;
    transition: all linear 0.2s;
    fill: $gray2;
  }
  .icon-error {
    // display: none;
    @include hidden;
  }
  &.error,
  .show-error,
  &.show-error {
    position: relative;
    .form__icon {
      @include hidden;
    }
    .icon-error {
      // display: block;
      @include visible;
      fill: $red;
    }
  }

  &.error {
  }
}

.form__input {
  font-size: 1em;
  &[type='text'],
  &[type='number'],
  &[type='password'],
  &[type='tel'],
  &[type='email'],
  &--text {
    color: $color1;
    width: 100%;
    border-radius: 1.1em;
    outline: none;
    border: 1px solid $color6;
    padding: 0.5em 1.1em;
    line-height: 2.2;
    transition: all linear 0.2s;
    &::placeholder {
      color: $gray;
    }
  }
  &:focus {
    border-color: $blue;
  }
  &:disabled,
  &.inactive {
    color: #ddd;
    background-color: #fafbfb;
    &::placeholder {
      color: transparent;
    }
  }
  &.inactive {
    pointer-events: none;
  }
  &--checkbox {
    @include hidden;
    position: absolute;
    & + label {
      color: $gray;
      display: inline-flex;
      user-select: none;
      // cursor: pointer;
      &:before {
        content: '';
        cursor: pointer;
        transition: all linear 0.1s;
        margin-right: 0.5em;
        width: 1.22em;
        height: 1.22em;
        flex: 0 0 1.22em;
        border-radius: 7px;
        background-repeat: no-repeat;
        background-color: transparent;
        border: 1px solid $color6;
        background-position: center;
        background-size: 12px 10px;
      }
    }
    &:checked + label {
      &::before {
        background-color: $blue;
        border-color: $blue;
        background-image: url('data:image/svg+xml;utf8,<svg  viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7142 1.85718L4.23207 8.45718L1.28564 5.45718" fill="%234884FA"/><path d="M10.7142 1.85718L4.23207 8.45718L1.28564 5.45718" stroke="white" stroke-width="1.79756" stroke-linecap="round" stroke-linejoin="round"/></svg>');
      }
    }
    &.error + label {
      &::before {
        border-color: $red;
      }
    }
  }
  &--radio {
  }
  &--list {
    & + .datalist {
      @include hidden;
      list-style: none;
      position: absolute;
      // top: 60px;
      top: 0;
      left: 0;
      background-color: #fff;
      border-top: none;
      width: 100%;
      // padding: 1em 1.1em;
      padding: 3.5em 1.1em 1em 1.1em;
      // max-height: 10rem;
      max-height: 12.9em;
      border-radius: 1.1em;
      box-shadow: 0px 23px 37px rgba(156, 165, 184, 0.22);

      margin: 0;
      overflow-y: auto;
      border: 1px solid #fff;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
      &:before {
        // display: none;
        // background-color: #fff;
        // display: block;
        // content: '';
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: calc(100% + 60px);
      }
      &.active {
        @include visible;
        z-index: 700;
        &:before {
          // display: block;
          // z-index: 699;
        }
      }
      .datalist__option {
        list-style: none;
        display: block;
        padding: 4px 0;
        // font-size: 18px;
        cursor: pointer;
        // color: $gray;
        &:hover,
        &.active {
          color: $blue;
        }
      }
    }
    &.focus,
    &:focus {
      position: relative;
      z-index: 701;
      border-color: #fff;
    }
  }

  &.error {
    border-color: $red;
  }
  &.success {
    border-color: $green;
  }
}

.label__link {
  color: inherit;
  &:hover {
    color: $color2;
  }
}

.form__row {
  // display: flex;
  margin: 1em 0;
  & > .form__item {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: $xsmall) {
    display: flex;
    gap: 1em;
    margin: 1em 0;
    & > .form__item {
      margin-top: 0;
      margin-bottom: 0;
      flex: 0 0 50%;
      &--cvv {
        flex: 0 0 32%;
      }
      &--exp,
      &--pcode {
        flex: 1 1 100%;
      }
    }
  }
}
.form__text {
  text-align: center;
  // color: $gray;
  max-width: 44rem !important;
  &.plans__message {
    color: $gray;
    p {
      margin: 0;
    }
  }
}
.form__subtitle {
  font-size: 1.222em;
  @media screen and (max-width: $medium) {
    display: flex;
    justify-content: space-between;
  }
}

.form__label {
  // align-items: center;
  .icon-info {
    // font-size: ;
    position: relative;
    margin-top: 0;
    top: initial;
    right: inherit;
    margin-left: 0.5em;
  }
}

.link-forgot {
  color: $gray;
  svg {
    fill: $gray;
    stroke: $gray;
  }
}

.form__header {
  //   margin-top: 0;
  margin-bottom: auto;
  @media screen and (min-width: $small) {
    margin-bottom: 7vh;
  }
}
.form__footer {
  margin-top: auto;
  margin-bottom: 1em;
  @media screen and (min-width: $small) {
    margin-top: 5vh;
    margin-bottom: 1em;
  }
  &-login {
    @media screen and (min-width: $xmedium) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  .form__submit {
    input {
      min-width: 8.5em;
      width: 100%;
      @media screen and (min-width: $small) {
        width: auto;
      }
    }
    @media screen and (min-width: $xmedium) {
      margin-right: 1em;
    }
  }
  .form__item--toggle {
    margin-bottom: 1em;
    @media screen and (min-width: $xmedium) {
      margin-bottom: 0;
    }
  }
}

.form__join {
  text-align: center;
  padding-bottom: 1.5rem;
  svg {
    fill: $blue;
    stroke: $blue;
  }
  @media screen and (min-width: $small) {
    padding-bottom: 2rem;
  }
  @media screen and (min-width: $medium) {
    display: none;
  }
}
#error_explanation,
.error_explanation {
  ul {
    list-style: none;
    padding: 0;
    margin: 1em 0;
    color: $red;
  }
}
