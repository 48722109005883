@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @keyframes fadeInLeft {
//   from {
//     opacity: 0;
//     transform: translate3d(-100%, 0, 0);
//   }

//   to {
//     opacity: 1;
//     transform: translate3d(0, 0, 0);
//   }
// }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes greenBgFade {
  from {
    background-color: rgba(130, 229, 168, 0.1);
  }
  to {
    background-color: rgba(130, 229, 168, 0.4);
  }
}

// @keyframes zoomInLeft {
//   from {
//     opacity: 0;
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//   }

//   60% {
//     opacity: 1;
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//   }
// }
// @keyframes bounceInLeft {
//   from,
//   60%,
//   75%,
//   90%,
//   to {
//     animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
//   }

//   0% {
//     opacity: 0;
//     transform: translate3d(-3000px, 0, 0) scaleX(3);
//   }

//   60% {
//     opacity: 1;
//     transform: translate3d(25px, 0, 0) scaleX(1);
//   }

//   75% {
//     transform: translate3d(-10px, 0, 0) scaleX(0.98);
//   }

//   90% {
//     transform: translate3d(5px, 0, 0) scaleX(0.995);
//   }

//   to {
//     transform: translate3d(0, 0, 0);
//   }
// }
// .fadeInLeft {
//   animation-name: fadeInLeft;
// }

// @-webkit-keyframes in {
//   0% {
//     -webkit-transform: scale(0) rotate(12deg);
//     opacity: 0;
//     visibility: hidden;
//   }
//   100% {
//     -webkit-transform: scale(1) rotate(0);
//     opacity: 1;
//     visibility: visible;
//   }
// }

@keyframes in {
  0% {
    // transform: scale(0) rotate(12deg);
    opacity: 0;
    visibility: hidden;
  }
  100% {
    // transform: scale(1) rotate(0);
    opacity: 1;
    visibility: visible;
  }
}

// @-webkit-keyframes out {
//   0% {
//     -webkit-transform: scale(1) rotate(0);
//     opacity: 1;
//     visibility: visible;
//   }
//   100% {
//     -webkit-transform: scale(0) rotate(-12deg);
//     opacity: 0;
//     visibility: hidden;
//   }
// }

@keyframes out {
  0% {
    // transform: scale(1) rotate(0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    // transform: scale(0) rotate(-12deg);
    opacity: 0;
    visibility: hidden;
  }
}

body {
  animation-name: fadeIn;
  animation-delay: 0;
  animation-duration: 3s;
}

html {
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: #f3f6f7;
}
::-webkit-scrollbar-thumb {
  background: rgba(72, 77, 85, 0.14);
  border-radius: 20px;
  transition: all linear 0.2s;
}
::-webkit-scrollbar-thumb:hover {
  background: $gray;
}

.hidden {
  display: none !important;
}

.invisible {
  position: absolute;
  @include hidden;
}

.clearfix {
  *zoom: 1;

  &:before {
    content: ' ';
    display: table;
  }

  &:after {
    clear: both;
    content: ' ';
    display: table;
  }
}

body,
html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

nav {
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

textarea {
  // width: 100%; //?
  resize: vertical;
}

a {
  color: $blue;
  text-decoration: none;
  &.link--action {
    cursor: pointer;
    border-bottom: 1px dashed;
  }
}

h1 {
  font-size: 1.75em;
  // &:first-child {
  //     margin-top: 0;
  // }
}

h2 {
  font-size: 1.25em;
}
h3 {
  font-size: 1.125em;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
  &:first-child {
    margin-top: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: $blue;
    }
  }
}

button,
select,
input,
textarea {
  font-family: inherit;
}

// @keyframes fadein {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .container {
//   width: 100%;
//   max-width: 69em;
//   margin: 0 auto;
//   padding: 0 2em;
//   &--wide { max-width: 94em; }
//   &--flex {
//     display: flex;
//     @media screen and (max-width: $xmedium) {
//     }
//     @media screen and (max-width: $xsmall) {
//     }
//   }
//   @media screen and (max-width: $medium) {
//     padding: 0 1.5em;
//   }
//   @media screen and (max-width: $small) {
//     padding: 0 1em;
//   }
// }

.disabled {
  pointer-events: none;
}

a,
button,
.btn,
.popup,
.modal {
  transition: all ease-in-out 0.3s;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.row {
  display: flex;
  .col {
    flex: 1 1 100%;
    &--1-2 {
      flex-shrink: 0;
      flex-basis: 50%;
    }
    &--1-3 {
      flex-shrink: 0;
      flex-basis: 33%;
    }
    &--2-3 {
      flex-shrink: 0;
      flex-basis: 66%;
    }
    &--3-4 {
      flex-shrink: 0;
      flex-basis: 75%;
    }
  }
  // &--2 {
  //   .col {
  //     flex: 0 0 50%;
  //   }
  // }
  // &--3 {
  //   .col {
  //   }
  // }
  // &--4 {
  //   .col {
  //   }
  // }
  // &--6 {
  //   .col {
  //   }
  // }
  &--wrap {
    flex-wrap: wrap;
  }
}

// .mobile-hidden {
//   @media screen and (max-width: $xmedium) {
//     display: none !important;
//   }
// }
// .desktop-hidden {
//   @media screen and (min-width: $xmedium + 1px) {
//     display: none !important;
//   }
// }
.mobile-hidden {
  // @media screen and (max-width: $small) {
  @media screen and (max-width: 640px) {
    display: none !important;
  }
}
.desktop-hidden {
  // @media screen and (min-width: calc(#{$small} + 1px)) {
  @media screen and (min-width: 641px) {
    display: none !important;
  }
}

// hidden after breakpoint
.s-hidden {
  @media screen and (min-width: 641px) {
    display: none !important;
  }
}
.m-hidden {
  @media screen and (min-width: 969px) {
    display: none !important;
  }
}
.l-hidden {
  @media screen and (min-width: 1191px) {
    display: none !important;
  }
}

// visible after breakpoint
.sv {
  @media screen and (max-width: $small) {
    display: none !important;
  }
}
.mv {
  @media screen and (max-width: $xmedium) {
    display: none !important;
  }
}
.xmv {
  @media screen and (max-width: $xxmedium) {
    display: none !important;
  }
}
.lv {
  @media screen and (max-width: $large) {
    display: none !important;
  }
}

.text--center {
  text-align: center;
}

// Отладка
// .error,
.red,
.bad,
.fail {
  color: $red;
}
.green,
.good,
.success {
  color: $green;
}

.row {
  display: flex;
  margin: 0 -1em;
  @media screen and (max-width: $xmedium) {
    display: block;
  }
  & > .col {
    margin: 0;
    padding: 1em;
    flex: 0 0 50%;
  }
  &--3,
  &--three {
    & > .col {
      flex-basis: 33%;
    }
  }
  &--4,
  &--four {
    & > .col {
      flex-basis: 25%;
    }
  }
  &--wrap {
    flex-wrap: wrap;
  }
}

.nobr {
  white-space: nowrap;
}
