@mixin btn {
  cursor: pointer;
  border: none;
  outline: none;
}
@mixin btnIcon {
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@mixin btnText {
  min-width: 11em;
  font-size: 1em;
  // line-height: 3.33em;
  line-height: 60px;
  text-align: center;
  padding: 0 2em;
  border-radius: 1em;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  transition: all linear 0.3s;
  &:hover {
    transform: scale(1.03);
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
}

.btn__row,
.btn__container {
  button .button .btn,
  [class*='btn'],
  [class*='button'] {
    margin: 0.5em;
  }
  &--center {
    text-align: center;
  }
}

button,
.btn,
.button,
.btn__text,
.btn__img,
.btn__icon,
.btn__icon-text {
  @include btn;
}
.button,
.btn__text,
[class*='btn--'],
[class*='btn__text--'],
[class*='button--'] {
  @include btnText;
}
.button {
  color: #fff;
  background-color: $blue;
  fill: #fff;
}
.button,
.btn,
.btn__text {
  &--primary {
    color: #fff;
    background-color: $blue;
    &:disabled {
      color: rgba(255, 255, 255, 0.3);
    }
  }
  &--secondary {
    color: $gray;
    background-color: $light;
  }
  &--tertiary {
  }
  &--small {
    font-size: 0.875em;
  }
  &--long,
  &--wrap {
    white-space: normal;
  }
  &--disable,
  &--disabled,
  &:disabled,
  &.disabled-action {
    cursor: default;
    &:hover {
      transform: none;
    }
  }
  &--attention {
    color: $red;
  }

  &--inactive {
    opacity: 0.5;
  }
}

.btn__img,
.btn__icon,
.btn__icon-text {
  @include btnIcon;
  @include transparent;
  // span {
  //   color: $gray;
  //   font-weight: 700;
  //   margin-right: 0.5em;
  // }
  svg {
    flex-shrink: 0;
  }
  // &:focus {
  //   outline: 1px solid #ccc;
  // }
}
.btn-apply {
  flex-shrink: 0;
  min-width: 100%;
  &.applied {
    background-color: $green;
  }
  &:disabled {
    color: #fff;
  }
  @media screen and (min-width: $xsmall) {
    padding-left: 1.5em;
    padding-right: 1.5em;
    min-width: 0;
  }
}
