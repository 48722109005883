$font1: 'Sharp Sans Display No2', sans-serif;
$font: $font1;

$color1: #343c4c;
$dark: $color1;
$text: $color1;

$color2: #4884fa;
$blue: $color2;

$color3: #858ea3;
$grey: $color3;
$gray: $grey;

$grey2: #bbc1ce;
$gray2: $grey2;

$grey3: #9ca5b8;
$gray3: $grey3;

$color4: #f1f4f9;
$light: $color4;

$color6: #dce0e6;
$color6: #6d7790;
$lighter: $color6;

$color5: #82e5a8;
$green: $color5;

$red: #ff6b57;

// $xxlarge: 120rem;
// $xlarge: 100rem;
// $large: 90rem;
// $xxmedium: 67.5rem;
// $xmedium: 60.5rem;
// $medium: 50rem;
// $small: 40rem;
// $xsmall: 23.4375rem;
// $xxsmall: 23.4375rem;
// $xxxsmall: 20rem;
$xxlarge: 1920px; // 120rem
$xlarge: 1600px; // 100rem
$large: 1440px; // 90rem
// $xxmedium: 1190px; // 74.375
$xxmedium: 1080px; // 67.5rem
$xmedium: 968px; // 60.5
$medium: 800px; // 50rem
$small: 640px; // 40rem
$xsmall: 480px; // 23.4375
$xxsmall: 375px; // 23.4375
$xxxsmall: 320px; // 20

// $column-width: 240px;
// $column-gutter: 30px;

// @media screen and (max-width: $xxmedium) {}
// @media screen and (max-width: $xmedium) {}
// @media screen and (max-width: $medium) {}
// @media screen and (max-width: $small) {}

// 14 0.875
// 16 1
// 20 1.25
// 24 1.5

@mixin hidden {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  // pointer-events: none;
}

@mixin visible {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}

@mixin button {
  outline: none;
  box-shadow: none;
}

@mixin column {
  display: flex;
  flex-direction: column;
}
@mixin container {
  width: 100%;
  max-width: $xxmedium;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2em;
  padding-right: 2em;
}
@mixin transparent {
  outline: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}
