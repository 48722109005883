/*
 * This is a manifest file that'll be compiled into login.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, vendor/assets/stylesheets,
 * or any plugin's vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any styles
 * defined in the other CSS/SCSS files in this directory. It is generally better to create a new
 * file per style scope.
 *
 */
@font-face {
  font-family: "Sharp Sans Display No2";
  src: url("fonts/SharpSansDispNo2-Extrabold.woff2") format("woff2"), url("fonts/SharpSansDispNo2-Extrabold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sharp Sans Display No2";
  src: url("fonts/SharpSansDispNo2-Bold.woff2") format("woff2"), url("fonts/SharpSansDispNo2-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sharp Sans Display No2";
  src: url("fonts/SharpSansDispNo2-Semibold.woff2") format("woff2"), url("fonts/SharpSansDispNo2-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Sharp Sans Display No2";
  src: url("fonts/SharpSansDispNo2-Medium.woff2") format("woff2"), url("fonts/SharpSansDispNo2-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes greenBgFade {
  from {
    background-color: rgba(130, 229, 168, 0.1);
  }
  to {
    background-color: rgba(130, 229, 168, 0.4);
  }
}
@keyframes in {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
body {
  animation-name: fadeIn;
  animation-delay: 0;
  animation-duration: 3s;
}

html {
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-track {
  background: #f3f6f7;
}

::-webkit-scrollbar-thumb {
  background: rgba(72, 77, 85, 0.14);
  border-radius: 20px;
  transition: all linear 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background: #858ea3;
}

.hidden {
  display: none !important;
}

.invisible {
  position: absolute;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}

.clearfix {
  *zoom: 1;
}
.clearfix:before {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
  content: " ";
  display: table;
}

body,
html {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

nav ul,
nav li {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

a {
  color: #4884fa;
  text-decoration: none;
}
a.link--action {
  cursor: pointer;
  border-bottom: 1px dashed;
}

h1 {
  font-size: 1.75em;
}

h2 {
  font-size: 1.25em;
}

h3 {
  font-size: 1.125em;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 700;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
  color: inherit;
  text-decoration: none;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover {
  color: #4884fa;
}

button,
select,
input,
textarea {
  font-family: inherit;
}

.disabled {
  pointer-events: none;
}

a,
button,
.btn,
.popup,
.modal {
  transition: all ease-in-out 0.3s;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.row {
  display: flex;
}
.row .col {
  flex: 1 1 100%;
}
.row .col--1-2 {
  flex-shrink: 0;
  flex-basis: 50%;
}
.row .col--1-3 {
  flex-shrink: 0;
  flex-basis: 33%;
}
.row .col--2-3 {
  flex-shrink: 0;
  flex-basis: 66%;
}
.row .col--3-4 {
  flex-shrink: 0;
  flex-basis: 75%;
}
.row--wrap {
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .mobile-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .desktop-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 641px) {
  .s-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 969px) {
  .m-hidden {
    display: none !important;
  }
}

@media screen and (min-width: 1191px) {
  .l-hidden {
    display: none !important;
  }
}

@media screen and (max-width: 640px) {
  .sv {
    display: none !important;
  }
}

@media screen and (max-width: 968px) {
  .mv {
    display: none !important;
  }
}

@media screen and (max-width: 1080px) {
  .xmv {
    display: none !important;
  }
}

@media screen and (max-width: 1440px) {
  .lv {
    display: none !important;
  }
}

.text--center {
  text-align: center;
}

.red,
.bad,
.fail {
  color: #ff6b57;
}

.green,
.good,
.success {
  color: #82e5a8;
}

.row {
  display: flex;
  margin: 0 -1em;
}
@media screen and (max-width: 968px) {
  .row {
    display: block;
  }
}
.row > .col {
  margin: 0;
  padding: 1em;
  flex: 0 0 50%;
}
.row--3 > .col, .row--three > .col {
  flex-basis: 33%;
}
.row--4 > .col, .row--four > .col {
  flex-basis: 25%;
}
.row--wrap {
  flex-wrap: wrap;
}

.nobr {
  white-space: nowrap;
}

label {
  display: block;
}

.form__container {
  width: 100%;
  max-width: 23em;
  margin-left: auto;
  margin-right: auto;
}
.form__container.map {
  max-width: 35em;
}

.form__warning {
  position: absolute;
  margin: 1em 0;
  padding-left: 1em;
  height: 1px;
  overflow: hidden;
  opacity: 0;
  transition: all linear 0.1s;
}
.form__warning.active {
  position: relative;
  height: auto;
  overflow: visible;
  opacity: 1;
}
.form__warning:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background-color: #ff6b57;
  left: 0;
  top: 0;
  border-radius: 3px;
}

.form__item {
  margin-bottom: 1em;
  position: relative;
}
.form__item--toggle {
  position: relative;
}
.form__item--toggle input[type=checkbox] {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
.form__item--toggle input[type=checkbox] + label {
  position: relative;
  cursor: pointer;
  user-select: none;
  font-weight: 400;
  display: flex;
  flex: 1 1 100%;
  align-items: center;
}
.form__item--toggle input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 2.75rem;
  height: 1.75rem;
  border-radius: 3.25em;
  background: #aeb8c9;
  border: 0.25rem solid #aeb8c9;
  margin-right: 0.5em;
  background-image: url('data:image/svg+xml,<svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9.21427" cy="9.21427" r="9.21427" transform="matrix(-1 0 0 1 18.4285 0.785156)" fill="white"/></svg>');
  background-size: 1.25rem;
  background-position: left center;
  background-repeat: no-repeat;
  transition: all linear 0.2s;
}
.form__item--toggle input[type=checkbox] + label:hover {
  color: #858ea3;
}
.form__item--toggle input[type=checkbox]:checked + label:before {
  background-position: right center;
  background-color: #4884fa;
  border-color: #4884fa;
}
.form__item--password .btn-showpass {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.form__item--password .btn-hidepass {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
.form__item--password.show .btn-showpass {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
.form__item--password.show .btn-hidepass {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.form__item .form__icon,
.form__item .form__tooltip,
.form__item .icon-error {
  position: absolute;
  width: 1.34em;
  height: 1.34em;
  right: 1.2em;
  top: 50%;
  margin-top: -0.67em;
  transition: all linear 0.2s;
  fill: #bbc1ce;
}
.form__item .icon-error {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
.form__item.error,
.form__item .show-error, .form__item.show-error {
  position: relative;
}
.form__item.error .form__icon,
.form__item .show-error .form__icon, .form__item.show-error .form__icon {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
.form__item.error .icon-error,
.form__item .show-error .icon-error, .form__item.show-error .icon-error {
  visibility: visible;
  opacity: 1;
  z-index: 100;
  fill: #ff6b57;
}
.form__input {
  font-size: 1em;
}
.form__input[type=text], .form__input[type=number], .form__input[type=password], .form__input[type=tel], .form__input[type=email], .form__input--text {
  color: #343c4c;
  width: 100%;
  border-radius: 1.1em;
  outline: none;
  border: 1px solid #6d7790;
  padding: 0.5em 1.1em;
  line-height: 2.2;
  transition: all linear 0.2s;
}
.form__input[type=text]::placeholder, .form__input[type=number]::placeholder, .form__input[type=password]::placeholder, .form__input[type=tel]::placeholder, .form__input[type=email]::placeholder, .form__input--text::placeholder {
  color: #858ea3;
}
.form__input:focus {
  border-color: #4884fa;
}
.form__input:disabled, .form__input.inactive {
  color: #ddd;
  background-color: #fafbfb;
}
.form__input:disabled::placeholder, .form__input.inactive::placeholder {
  color: transparent;
}
.form__input.inactive {
  pointer-events: none;
}
.form__input--checkbox {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  position: absolute;
}
.form__input--checkbox + label {
  color: #858ea3;
  display: inline-flex;
  user-select: none;
}
.form__input--checkbox + label:before {
  content: "";
  cursor: pointer;
  transition: all linear 0.1s;
  margin-right: 0.5em;
  width: 1.22em;
  height: 1.22em;
  flex: 0 0 1.22em;
  border-radius: 7px;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 1px solid #6d7790;
  background-position: center;
  background-size: 12px 10px;
}
.form__input--checkbox:checked + label::before {
  background-color: #4884fa;
  border-color: #4884fa;
  background-image: url('data:image/svg+xml;utf8,<svg  viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.7142 1.85718L4.23207 8.45718L1.28564 5.45718" fill="%234884FA"/><path d="M10.7142 1.85718L4.23207 8.45718L1.28564 5.45718" stroke="white" stroke-width="1.79756" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.form__input--checkbox.error + label::before {
  border-color: #ff6b57;
}
.form__input--list + .datalist {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  list-style: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border-top: none;
  width: 100%;
  padding: 3.5em 1.1em 1em 1.1em;
  max-height: 12.9em;
  border-radius: 1.1em;
  box-shadow: 0px 23px 37px rgba(156, 165, 184, 0.22);
  margin: 0;
  overflow-y: auto;
  border: 1px solid #fff;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.form__input--list + .datalist::-webkit-scrollbar {
  display: none;
}
.form__input--list + .datalist.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
  z-index: 700;
}
.form__input--list + .datalist .datalist__option {
  list-style: none;
  display: block;
  padding: 4px 0;
  cursor: pointer;
}
.form__input--list + .datalist .datalist__option:hover, .form__input--list + .datalist .datalist__option.active {
  color: #4884fa;
}
.form__input--list.focus, .form__input--list:focus {
  position: relative;
  z-index: 701;
  border-color: #fff;
}
.form__input.error {
  border-color: #ff6b57;
}
.form__input.success {
  border-color: #82e5a8;
}

.label__link {
  color: inherit;
}
.label__link:hover {
  color: #4884fa;
}

.form__row {
  margin: 1em 0;
}
.form__row > .form__item:first-child {
  margin-top: 0;
}
.form__row > .form__item:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 480px) {
  .form__row {
    display: flex;
    gap: 1em;
    margin: 1em 0;
  }
  .form__row > .form__item {
    margin-top: 0;
    margin-bottom: 0;
    flex: 0 0 50%;
  }
  .form__row > .form__item--cvv {
    flex: 0 0 32%;
  }
  .form__row > .form__item--exp, .form__row > .form__item--pcode {
    flex: 1 1 100%;
  }
}

.form__text {
  text-align: center;
  max-width: 44rem !important;
}
.form__text.plans__message {
  color: #858ea3;
}
.form__text.plans__message p {
  margin: 0;
}

.form__subtitle {
  font-size: 1.222em;
}
@media screen and (max-width: 800px) {
  .form__subtitle {
    display: flex;
    justify-content: space-between;
  }
}

.form__label .icon-info {
  position: relative;
  margin-top: 0;
  top: initial;
  right: inherit;
  margin-left: 0.5em;
}

.link-forgot {
  color: #858ea3;
}
.link-forgot svg {
  fill: #858ea3;
  stroke: #858ea3;
}

.form__header {
  margin-bottom: auto;
}
@media screen and (min-width: 640px) {
  .form__header {
    margin-bottom: 7vh;
  }
}

.form__footer {
  margin-top: auto;
  margin-bottom: 1em;
}
@media screen and (min-width: 640px) {
  .form__footer {
    margin-top: 5vh;
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 968px) {
  .form__footer-login {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}
.form__footer .form__submit input {
  min-width: 8.5em;
  width: 100%;
}
@media screen and (min-width: 640px) {
  .form__footer .form__submit input {
    width: auto;
  }
}
@media screen and (min-width: 968px) {
  .form__footer .form__submit {
    margin-right: 1em;
  }
}
.form__footer .form__item--toggle {
  margin-bottom: 1em;
}
@media screen and (min-width: 968px) {
  .form__footer .form__item--toggle {
    margin-bottom: 0;
  }
}

.form__join {
  text-align: center;
  padding-bottom: 1.5rem;
}
.form__join svg {
  fill: #4884fa;
  stroke: #4884fa;
}
@media screen and (min-width: 640px) {
  .form__join {
    padding-bottom: 2rem;
  }
}
@media screen and (min-width: 800px) {
  .form__join {
    display: none;
  }
}

#error_explanation ul,
.error_explanation ul {
  list-style: none;
  padding: 0;
  margin: 1em 0;
  color: #ff6b57;
}

.btn__row button .button .btn,
.btn__row [class*=btn],
.btn__row [class*=button],
.btn__container button .button .btn,
.btn__container [class*=btn],
.btn__container [class*=button] {
  margin: 0.5em;
}
.btn__row--center,
.btn__container--center {
  text-align: center;
}

button,
.btn,
.button,
.btn__text,
.btn__img,
.btn__icon,
.btn__icon-text {
  cursor: pointer;
  border: none;
  outline: none;
}

.button,
.btn__text,
[class*=btn--],
[class*=btn__text--],
[class*=button--] {
  min-width: 11em;
  font-size: 1em;
  line-height: 60px;
  text-align: center;
  padding: 0 2em;
  border-radius: 1em;
  font-weight: 600;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  transition: all linear 0.3s;
}
.button:hover,
.btn__text:hover,
[class*=btn--]:hover,
[class*=btn__text--]:hover,
[class*=button--]:hover {
  transform: scale(1.03);
}

.button {
  color: #fff;
  background-color: #4884fa;
  fill: #fff;
}

.button--primary,
.btn--primary,
.btn__text--primary {
  color: #fff;
  background-color: #4884fa;
}
.button--primary:disabled,
.btn--primary:disabled,
.btn__text--primary:disabled {
  color: rgba(255, 255, 255, 0.3);
}
.button--secondary,
.btn--secondary,
.btn__text--secondary {
  color: #858ea3;
  background-color: #f1f4f9;
}
.button--small,
.btn--small,
.btn__text--small {
  font-size: 0.875em;
}
.button--long, .button--wrap,
.btn--long,
.btn--wrap,
.btn__text--long,
.btn__text--wrap {
  white-space: normal;
}
.button--disable, .button--disabled, .button:disabled, .button.disabled-action,
.btn--disable,
.btn--disabled,
.btn:disabled,
.btn.disabled-action,
.btn__text--disable,
.btn__text--disabled,
.btn__text:disabled,
.btn__text.disabled-action {
  cursor: default;
}
.button--disable:hover, .button--disabled:hover, .button:disabled:hover, .button.disabled-action:hover,
.btn--disable:hover,
.btn--disabled:hover,
.btn:disabled:hover,
.btn.disabled-action:hover,
.btn__text--disable:hover,
.btn__text--disabled:hover,
.btn__text:disabled:hover,
.btn__text.disabled-action:hover {
  transform: none;
}
.button--attention,
.btn--attention,
.btn__text--attention {
  color: #ff6b57;
}
.button--inactive,
.btn--inactive,
.btn__text--inactive {
  opacity: 0.5;
}

.btn__img,
.btn__icon,
.btn__icon-text {
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
}
.btn__img svg,
.btn__icon svg,
.btn__icon-text svg {
  flex-shrink: 0;
}

.btn-apply {
  flex-shrink: 0;
  min-width: 100%;
}
.btn-apply.applied {
  background-color: #82e5a8;
}
.btn-apply:disabled {
  color: #fff;
}
@media screen and (min-width: 480px) {
  .btn-apply {
    padding-left: 1.5em;
    padding-right: 1.5em;
    min-width: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body {
  font-family: "Sharp Sans Display No2", sans-serif;
  font-size: 1.125em;
  line-height: 1.4;
  font-weight: 500;
  color: #343c4c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100svh;
}
@media screen and (min-width: 1440px) {
  body {
    justify-content: flex-start;
  }
}
body.noscroll {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2em;
  padding-right: 2em;
}
.container--narrow {
  max-width: 25em;
}

a > .link--ext, a.link--ext {
  display: inline-block;
  transition: all linear 0.2s;
  fill: #858ea3;
  stroke: #858ea3;
}
a:hover > .link--ext, a.link--ext:hover {
  fill: #4884fa;
  stroke: #4884fa;
  transform: rotate(45deg);
}

.swiper-pagination-bullet {
  width: 5px;
  height: 5px;
  background-color: #dce0e6;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: #858ea3;
}

.overlay {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(21, 38, 71, 0.15);
  transition: opacity linear 0.1s;
}
.overlay.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
  z-index: 899;
}

[data-tooltip] {
  transition: all linear 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
[data-tooltip]:before {
  color: #343c4c;
  box-shadow: -7px 10px 38px rgba(156, 165, 184, 0.3);
  content: attr(data-tooltip);
  transition: all ease-in-out 0.2s;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  text-align: center;
  padding: 1em;
  border-radius: 1em;
  white-space: normal;
  width: 120%;
  min-width: 10em;
  max-width: 25em;
  bottom: 40px;
  position: absolute;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
[data-tooltip]:after {
  content: "";
  width: 20px;
  height: 10px;
  bottom: 30px;
  left: 50%;
  background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.31999 7.16942L3.27995 1.98009C2.59995 0.800086 1.36 0.0560652 0 6.52128e-05H19.48C18.1066 0.0573985 16.8533 0.813383 16.1733 2.00938L13.2399 7.14008C11.7066 9.81341 7.86665 9.82942 6.31999 7.16942Z" fill="white"/></svg>');
  transform: translateX(-50%);
  position: absolute;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition: all ease-in-out 0.2s;
}
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
  z-index: 100;
  z-index: 101;
}
[data-tooltip]:hover:before:hover, [data-tooltip]:hover:after:hover {
  visibility: hidden;
  z-index: -1;
  opacity: 0;
}
[data-tooltip].hover:before {
  bottom: calc(100% + 7px);
}
[data-tooltip].hover:after {
  bottom: 100%;
}
[data-tooltip].tooltip--wide:before {
  min-width: 250px;
}
[data-tooltip].tooltip--left:before {
  left: auto;
  right: -0.7em;
  transform: none;
  text-align: center;
}
[data-tooltip].tooltip--right:before {
  left: -1em;
  transform: none;
}

.popup {
  bottom: -100%;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  position: fixed;
  width: 100%;
  left: 0;
  transition: all ease-in-out 0.2s;
}
.popup__container {
  width: 425px;
  max-width: 90vw;
  background-color: #fff;
  padding: 35px;
  border-radius: 30px;
  margin: 0 auto;
}
.popup.active {
  bottom: 64px;
  visibility: visible;
  opacity: 1;
  z-index: 100;
  z-index: 999;
}
@media screen and (max-width: 800px) {
  .popup.active {
    bottom: 1em;
  }
}
.popup__title {
  font-size: 1.44em;
  text-align: center;
}
.popup__text {
  text-align: center;
}
.popup__text a {
  color: #343c4c;
}
.popup__text--narrow {
  max-width: 300px;
  margin: 0 auto 35px auto;
}
.popup__btns {
  display: flex;
  justify-content: center;
  margin-left: -5px;
  margin-right: -5px;
}
.popup__btns .btn {
  flex: 1 1 100%;
  min-width: auto;
  max-width: 175px;
  margin: 0 5px;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -45px;
  margin-left: -45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  background-color: rgba(130, 229, 168, 0.2);
  border-radius: 50px;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition: opacity ease 1s;
  animation: greenBgFade 2s infinite alternate;
}
.spinner.active {
  visibility: visible;
  opacity: 1;
  z-index: 100;
}
.spinner__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #82e5a8;
  width: 56px;
  height: 56px;
  border-radius: 50px;
}
.spinner__inner svg {
  width: 35px;
}

.icon-link {
  flex-shrink: 0;
}

.header {
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  animation-name: fadein;
  animation-duration: 0.5s;
}
@media screen and (min-width: 800px) {
  .header {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }
}
.header__join {
  display: none;
}
@media screen and (min-width: 800px) {
  .header__join {
    display: block;
    text-align: right;
  }
}
@media screen and (min-width: 800px) {
  .header {
    justify-content: space-between;
  }
}

.join__link svg {
  stroke: #4884fa;
  fill: #4884fa;
}

.main {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: fadein;
  animation-duration: 0.7s;
}
@media screen and (min-width: 640px) {
  .main {
    flex-direction: row;
  }
}
@media screen and (min-width: 800px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1440px) {
  .main {
    flex: 0 1 100%;
    margin-top: 10vh;
  }
}
.main .picture {
  display: none;
}
@media screen and (min-width: 800px) {
  .main .picture {
    display: block;
  }
}

.splash {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  background-color: #4884fa;
  transition: opacity linear 0.4s;
}
@media screen and (min-width: 800px) {
  .splash {
    display: none;
  }
}
.splash .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}
.splash.active {
  z-index: 999;
  pointer-events: all;
  opacity: 1;
  animation-name: fadein;
  animation-duration: 0.5s;
}
.splash__logo {
  flex-shrink: 0;
  margin-bottom: auto;
  text-align: center;
}
.splash__image {
  flex: 1 1 100%;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation-name: fadein;
  animation-duration: 0.8s;
}
.splash__image img {
  margin-right: -1em;
}
.splash .splash__footer {
  flex-shrink: 0;
  margin-top: auto;
}
.splash .splash__btns {
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 20px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 20em;
}
.splash .splash__btns .btn {
  border-radius: 20px;
  flex: 0 0 50%;
  line-height: 1.25rem;
  padding: 1.25rem;
  font-size: 1em;
  text-decoration: none;
}
.splash .splash__btns .btn-login {
  background-color: #fff;
  color: #4884fa;
}
.splash .splash__btns .btn-join {
  background-color: transparent;
  color: #fff;
  display: inline-flex;
  justify-content: center;
}
.splash .splash__btns .btn-join svg {
  flex-shrink: 0;
  stroke: #fff;
  fill: #fff;
}

.form-login {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  max-width: 385px;
}
@media screen and (min-width: 800px) {
  .form-login {
    justify-content: center;
  }
}

.picture {
  position: relative;
  width: 486px;
  margin-left: 1.5em;
  margin-right: -1.5em;
}
.picture .sticker {
  display: inline-flex;
  position: absolute;
  padding: 0.8em 0.9em;
  font-size: 0.85em;
  line-height: 130%;
  align-items: center;
  background-color: #fff;
  border-radius: 1.3em;
  box-shadow: 4px 12px 40px -10px rgba(0, 0, 0, 0.1);
  z-index: 800;
}
.picture .sticker__icon {
  flex-shrink: 0;
  border-radius: 0.85em;
  width: 2.5em;
  height: 2.5em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8em;
}
.picture .sticker > span {
  flex: 1 1 100%;
}
.picture .sticker-1 {
  right: 0;
  top: 18%;
}
@media screen and (min-width: 1440px) {
  .picture .sticker-1 {
    right: -7%;
  }
}
.picture .sticker-1 .sticker__icon {
  background-color: #82e5a8;
}
.picture .sticker-2 {
  left: -7%;
  bottom: 14%;
}
.picture .sticker-2 .sticker__icon {
  background-color: #4884fa;
}

.login-title {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.875rem;
  line-height: 110%;
}
@media screen and (min-width: 640px) {
  .login-title {
    font-size: 2rem;
  }
}
@media screen and (min-width: 800px) {
  .login-title {
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 1080px) {
  .login-title {
    font-size: 3rem;
  }
}
@media screen and (min-width: 1440px) {
  .login-title {
    font-size: 3.125rem;
  }
}

.login-subtitle {
  margin: 0;
}
.login-subtitle .accented {
  border-bottom: 4px solid #82e5a8;
}
@media screen and (min-width: 1080px) {
  .login-subtitle .accented {
    white-space: nowrap;
    border-bottom: none;
    display: inline-block;
    position: relative;
  }
  .login-subtitle .accented:after {
    content: "";
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-image: url('data:image/svg+xml,<svg width="319" height="8" viewBox="0 0 319 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3 5.10539C61.3333 1.93873 328.3 3.10541 315.5 5.10541" stroke="%2382E5A8" stroke-width="5" stroke-linecap="round"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
  }
}

.alert {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2em;
  padding-right: 2em;
  position: relative;
  margin-top: 1em;
  z-index: 800;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-right: 1.25em;
  border-radius: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #4884fa;
}
.alert:before {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  flex-shrink: 0;
}
.alert.alert-info, .alert.alert-success {
  background-color: rgb(115, 210, 152);
  padding-left: 1.25em;
}
.alert.alert-info:before, .alert.alert-success:before {
  content: "";
  background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.alert.alert-warning {
  color: #856404;
  background-color: #fff3cd;
}
.alert.alert-warning:before {
  background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.alert.alert-danger, .alert.alert-error {
  background-color: #d82626;
}
.alert.alert-danger:before, .alert.alert-error:before {
  background-image: url('data:image/svg+xml,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.11111L5.84615 9L12 2" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}
.alert .close {
  flex-shrink: 0;
  margin-left: auto;
  right: 1rem;
  top: 1rem;
  width: 1rem;
  height: 1rem;
  display: block;
  background-image: url('data:image/svg+xml,<svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.0001 1.59189L1.70263 13.3295" stroke="white" stroke-width="3" stroke-linecap="round"/><path d="M14.0001 13.4081L1.70264 1.67051" stroke="white" stroke-width="3" stroke-linecap="round"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: transparent;
}